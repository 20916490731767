<template>
	<div id="cpm_liveType">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<a-input-search v-model:value="key" placeholder="分类名称"  style="width: 400px;" enter-button @search="getTypeList(1,info.limit)" />
				<a-button type="primary" v-has="{action:'wxapp_live_type_add',plat:isShop}" @click="editType(0)"><i class="ri-add-line"></i>新增分类</a-button>
				<a-button type="danger" v-has="{action:'wxapp_live_type_del',plat:isShop}" @click="deleteLiveType(pks)"><i class="ri-delete-bin-5-line"></i>批量删除</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<a-table row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'分类名称',dataIndex:'name'},
				{title:'是否显示',dataIndex:'status',slots:{customRender:'status'}},
				{title:'排序',dataIndex:'rank'},
				{title:'最后更新时间',dataIndex:'update_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }" >
				<template #status="{record}">
					<a-tag :color="record.is_show == 1 ?'#00CC66':'#999'">{{record.is_show == 1 ?'显示':'隐藏'}}</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_live_type_add',is_plat:isShop }" @click="editType(record)"></kd-button>
						<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_live_type_del',is_plat:isShop }" @click="deleteLiveType(record.id)"></kd-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
				    show-size-changer
				    :default-current="info.page"
				    :total="info.count"
				    @showSizeChange="(p,e)=>{getTypeList(info.page,e)}"
					@change="(e)=>{getTypeList(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal v-model:visible="edit" title="监控分类新增/编辑" @ok="saveLiveType" width="500px" @cancel="edit = false">
		    <a-form ref="form" :model="addForm" :label-col="{span:4}" :wrapper-col="{span:16}">
		    	<a-form-item label="分类名称">
		    		<a-input v-model:value="addForm.name"></a-input>
		    	</a-form-item>
		    	<a-form-item label="分类排序" >
		    		<a-input v-model:value="addForm.rank" type="number"></a-input>
		    	</a-form-item>
		    </a-form>
		</a-modal>
	</div>
</template>
<script>
import live from '@/api/live.js'
import { reactive, toRefs } from 'vue'
export default{
	name:'page-live-type',
	props:{
		isShop:{
			type:Number,
			value:0
		}
	},
	setup(){
		const _d = reactive({
			key:"",
			pks:[],
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
			addForm:{
				id:0,
				name:'',
				rank:99
			},
			edit:false,
		})
		getLiveTypeList(1,10)

		//获取分类列表
		function getLiveTypeList(page,limit){
			live.getLiveTypeList(page,limit,{key:_d.key}).then(res=>{
				_d.info = { limit,...res }
			})
		}

		function deleteLiveType(id) {
			live.deleteLiveType(id).then(()=>{
				getLiveTypeList(_d.info.page,_d.info.limit)
			})
		}

		function editType(row){
			_d.addForm = {
				id:row.id ? row.id :0,
				name:row.name ? row.name :'',
				rank:row.rank ? row.rank :99
			}
			_d.edit = true
		}

		function saveLiveType(){
			live.createOrEditLiveType(_d.addForm).then(()=>{
				_d.edit = false
				getLiveTypeList(_d.info.page,_d.info.limit)
			})
		}

		return {
			...toRefs(_d),
			getLiveTypeList,
			deleteLiveType,
			editType,
			saveLiveType
		}
	}
}
</script>

<style>
</style>
